import React from "react";
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <header id="header-wrap" class="site-header clearfix">
      <div
        class="navbar navbar-default navbar-top"
        role="navigation"
        data-spy="affix"
        data-offset-top="50"
      >
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>

            <div class="logo-wrapper">
              <Link class="navbar-brand" to="/">
                <img src="/assets/img/logo.png" alt="Reads Collegiate" />
              </Link>
            </div>
          </div>
          <div class="navbar-collapse collapse">
            <ul class="nav navbar-nav navbar-right">
              <li>
                <Link class="active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/proud-faculty">Proud Faculty</Link>
              </li>
              <li>
                <Link to="/student-portal">Student Portal</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>

              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>

        <ul class="wpb-mobile-menu">
        <li>
                <Link class="active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/proud-faculty">Proud Faculty</Link>
              </li>
              <li>
                <Link to="/student-portal">Student Portal</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>

              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
        </ul>
      </div>
      <div class="clearfix"></div>
    </header>
  );
}
