import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import About from "./Components/About";
import ProudFaculty from "./Components/ProudFaculty";
import StudentPortal from "./Components/StudentPortal";
import Gallery from "./Components/Gallery";
import ContactUs from "./Components/ContactUs";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/proud-faculty" element={<ProudFaculty />} />
        <Route path="/student-portal" element={<StudentPortal />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
