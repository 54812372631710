import React from "react";

export default function Gallery() {
  return (
    <div>
      <div
        class="page-header"
        style={{
          background: "url(/assets/img/bg/banner.jpg)",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="entry-title">Gallery</h2>
              <div class="breadcrumb">
                <a href="index.html">Home</a>
                <span class="crumbs-spacer">
                  <i class="fa fa-angle-double-right"></i>
                </span>
                <span class="current">Gallery</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="portfolio" class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="/assets/img/gallery/img-1.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="/assets/img/gallery/img-1.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="/assets/img/gallery/img-2.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="/assets/img/gallery/img-2.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-3.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-3.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-4.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-4.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-5.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-5.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-6.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-6.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-7.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-7.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-8.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-8.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-9.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-9.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-10.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-10.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-11.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-11.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-12.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-12.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-13.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-13.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-14.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-14.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-15.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-15.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-16.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-16.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-17.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-17.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 gallery-item">
              <div class="portfolio-item">
                <img src="assets/img/gallery/img-18.jpg" alt="" />
                <div class="overlay">
                  <div class="icon">
                    <a href="assets/img/gallery/img-18.jpg" class="lightbox">
                      <i class="icon-magnifier center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
