import React from "react";

export default function About() {
  return (

    <div>
    <div
      class="page-header"
      style={{
        background: "url(/assets/img/bg/banner.jpg)",
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="entry-title">
              <span>About</span> Us
            </h2>
            <div class="breadcrumb">
              <a>Home</a>
              <span class="crumbs-spacer">
                <i class="fa fa-angle-double-right"></i>
              </span>
              <span class="current">About Us</span>
            </div>
          </div>
        </div>
      </div>
      </div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="col-md-12 p-0">
              <div class="col-md-6">
                <h2 class="big-title">About Us</h2>
                <p>
                  Reads Collegiate is a renowned Science and Commerce Institute
                  that amalgamates some of the brightest students and very
                  passionate teachers, along with the most innovative tools to
                  address basic academic problems and to eliminate the stumbling
                  blocks in the success of our students.
                </p>
                <p>
                  We place great care and effort into offering the very best in
                  instruction by providing students with opportunities in which
                  they can discover untapped strengths and nurture new concepts
                  all under the supervision of a well trained staff.
                </p>
                <p>
                  The faculty of Reads Collegiate is passionate about providing
                  an environment that fosters academic and personal growth and
                  awakens the potential that resides in each of our students.
                  They guide and shape students outside the classroom and are
                  passionate about teaching, as well as experts in their fields
                  and in the ages they teach.
                </p>
                <p>
                  Our commitment to the success has made us one of the best
                  coaching center for learning achievement and revamping the
                  skills of our students.
                </p>
              </div>
              <div class="col-md-6">
                <div class="featured-thumb">
                  <img
                    src="/assets/img/about/student.jpg"
                    class="img-responsive"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="col-md-12">
                <p>
                  At Reads Collegiate, education is characterized by intensive
                  personal attention to the individual student. We involve
                  parents by sharing the ward’s result in time by sending
                  results through our unique Android Application and SMS
                  service. Parents may also enquire about overall performance of
                  their ward either by visiting the campus or by making a phone
                  call. We believe that success of students depends on four
                  pillars, which are: CENTER, PARENTS, TEACHERS and STUDENTS
                  (C.P.T.S) which we called “THE SUCCESS SQUARE”. We at Reads
                  Collegiate worked assiduously to connect all these pillars
                  while educating outstanding students t o become creative
                  members of society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
