import React from "react";

export default function ContactUs() {
  return (
    <div>
      <div
        class="page-header"
        style={{
          background: "url(/assets/img/bg/banner.jpg)",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="entry-title">
                <span>Contact</span> Us
              </h2>
              <div class="breadcrumb">
                <a href="index.html">Home</a>
                <span class="crumbs-spacer">
                  <i class="fa fa-angle-double-right"></i>
                </span>
                <span class="current">Contact Us</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="content">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <h2 class="big-title">Contact Form</h2>

              <form
                role="form"
                id="contactForm"
                class="contact-form"
                data-toggle="validator"
                className="shake"
              >
                <div class="form-group">
                  <div class="controls">
                    <input
                      type="text"
                      id="name"
                      class="form-control"
                      placeholder="Name"
                      required
                      data-error="Please enter your name"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="controls">
                    <input
                      type="email"
                      class="email form-control"
                      id="email"
                      placeholder="Email"
                      required
                      data-error="Please enter your email"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="controls">
                    <input
                      type="text"
                      id="msg_subject"
                      class="form-control"
                      placeholder="Subject"
                      required
                      data-error="Please enter your message subject"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="controls">
                    <textarea
                      id="message"
                      rows="7"
                      placeholder="Massage"
                      class="form-control"
                      required
                      data-error="Write your message"
                    ></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <button type="submit" id="submit" class="btn btn-effect">
                  <i class="fa fa-check"></i> Send Message
                </button>
                <div id="msgSubmit" class="h3 text-center hidden"></div>
                <div class="clearfix"></div>
              </form>
            </div>
            <div class="col-md-3">
              <h2 class="big-title">Our Location</h2>
              <div class="information">
                <div class="contact-datails">
                  <p>
                    <i class="icon-pointer"></i>{" "}
                    <a
                      href="https://www.google.com/maps/d/viewer?mid=1buSjNo5bow1YlNMlqZkgwUZj0gokLwCD&ll=24.932309340152457%2C67.04374394999991&z=14"
                      target="_blank"
                    >
                      NAZIMABAD CAMPUS: 1-A, 1/2, FALAK AVENUE, NAZIMABAD # 1,
                      KARACHI
                    </a>
                  </p>
                  <p>
                    <i class="icon-call-out"></i> +021 366620040-41
                  </p>
                  <p>
                    <i class="icon-pointer"></i>{" "}
                    <a
                      href="https://www.google.com/maps/d/viewer?mid=1buSjNo5bow1YlNMlqZkgwUZj0gokLwCD&ll=24.932309340152457%2C67.04374394999991&z=14"
                      target="_blank"
                    >
                      SAKHI HASSAN CAMPUS: C-4 SECTOR 14-A, SHADMAN TOWN, NORTH
                      NAZIMABAD, KARACHI
                    </a>
                  </p>
                  <p>
                    <i class="icon-call-out"></i> +021 36952901, 36904286
                  </p>
                  <p>
                    <i class="icon-envelope"></i> info@readscollegiate.com{" "}
                  </p>
                  <p>
                    <i class="icon-clock"></i> Mon-Sat (10am-10pm)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us">
        <div class="container p-0">
          <div class="col-md-12 p-0">
            <div class="col-md-12 p-0">
              <div class="map-area">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1298.1141373577902!2d67.02922903819463!3d24.908236527196607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33fbbb0ba2129%3A0x3fc2bc58dde33470!2sReads%20Collegiate%20Nazimabad%20Campus!5e1!3m2!1sen!2s!4v1728655440377!5m2!1sen!2s" style={{width: "100%"}} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
