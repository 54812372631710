import React from "react";

export default function Home() {
  return (
    <div>
        <div id="iview2">
				{/* <div class="iview-caption caption1 text-center">
                <h1>READ!<br />In the name of your Lord,...</h1>
                <p>Glorious Quran 96:1</p>
                </div> */}
			</div>

      <section id="portfolio" class="section">
        <div class="container">
          <div class="row">
            <div class="heading">
              <div class="section-title">
                OUR <span>PROUD FACULTY</span>
              </div>
            </div>
          </div>

          <div id="portfolio-list">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-3 col-sm-6 p-b-15 english">
                  <div class="team-member">
                    <div class="team-thumbnail">
                      <img
                        src="/assets/img/team/Hamid_Khan_English.png"
                        alt=""
                      />
                      <div class="overlay">
                        <div class="info name">
                          <h4>Muhammad Hamid_Khan</h4>
                          <p>CEO & English Lecturer</p>
                        </div>
                        <div class="social-media">
                          <a
                            href="https://www.facebook.com/readscollegiate.edu.pk"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a
                            href="https://twitter.com/ReadsCollegiate"
                            target="_blank"
                            class="twitter"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/readscollegiate"
                            target="_blank"
                            class="instagram"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="info">
                      <h4>Muhammad Hamid_Khan</h4>
                      <p>CEO & English Lecturer</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 p-b-15 physics">
                  <div class="team-member">
                    <div class="team-thumbnail">
                      <img
                        src="/assets/img/team/Syed_Anwar_Ul_Haq_Physics.png"
                        alt=""
                      />
                      <div class="overlay">
                        <div class="info name">
                          <h4>Syed Anwar Ul Haq</h4>
                          <p>Director & Physics Lecturer</p>
                        </div>
                        <div class="social-media">
                          <a
                            href="https://www.facebook.com/readscollegiate.edu.pk"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a
                            href="https://twitter.com/ReadsCollegiate"
                            target="_blank"
                            class="twitter"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/readscollegiate"
                            target="_blank"
                            class="instagram"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="info">
                      <h4>Syed Anwar Ul Haq</h4>
                      <p>Director & Physics Lecturer</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 p-b-15 biology">
                  <div class="team-member">
                    <div class="team-thumbnail">
                      <img
                        src="/assets/img/team/Umair_Khan_Biology.png"
                        alt=""
                      />
                      <div class="overlay">
                        <div class="info name">
                          <h4>Muhammad Umair Khan</h4>
                          <p>Director & Biology Lecturer</p>
                        </div>
                        <div class="social-media">
                          <a
                            href="https://www.facebook.com/readscollegiate.edu.pk"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a
                            href="https://twitter.com/ReadsCollegiate"
                            target="_blank"
                            class="twitter"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/readscollegiate"
                            target="_blank"
                            class="instagram"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="info">
                      <h4>Muhammad Umair Khan</h4>
                      <p>Director & Biology Lecturer</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 p-b-15 physics">
                  <div class="team-member">
                    <div class="team-thumbnail">
                      <img src="/assets/img/team/team-04.png" alt="" />
                      <div class="overlay">
                        <div class="info name">
                          <h4>Qazi Ekram Muneer</h4>
                          <p>Director & Physics Lecturer</p>
                        </div>
                        <div class="social-media">
                          <a
                            href="https://www.facebook.com/readscollegiate.edu.pk"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a
                            href="https://twitter.com/ReadsCollegiate"
                            target="_blank"
                            class="twitter"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/readscollegiate"
                            target="_blank"
                            class="instagram"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="info">
                      <h4>Qazi Ekram Muneer</h4>
                      <p>Director & Physics Lecturer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style={{ display: "none" }}>
              <div class="error-page">
                <a class="btn btn-larg btn-effect">MORE PROUD FACULTY</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="project section">
        <div class="container">
          <div class="heading">
            <div class="section-title">
              LATEST <span>NEWS AND EVENTS</span>
            </div>
          </div>

          <p class="description text-center">
            Reads Collegiate now offering video lectures for all subjects in
            this lockdown time to study at home. Stay Home, Stay Safe.
          </p>
          <div class="row">
            <div id="projects-carousel" class="touch-carousel">
              <div class="col-md-4">
                <div class="projects-box item">
                  <div class="projects-thumb">
                    <a href="#">
                      <img src="/assets/img/news/img1.jpg" alt="" />
                    </a>
                  </div>
                  <div class="projects-content">
                    <h4>
                      <a href="#">There are many variations</a>
                    </h4>
                    <div class="recent-meta">
                      <span class="date">
                        <i class="fa fa-file-text-o"></i> SEPT 12, 2015
                      </span>
                      <span class="comments">
                        <i class="fa fa-comments-o"></i> No Comments
                      </span>
                    </div>
                    <p class="projects-desc">
                      Constructor explains how you can enjoy high end flooring
                      trends like textured wood and realistic stones with new
                      laminate flooring.
                    </p>
                    <a href="#" class="btn btn-effect">
                      Read More <i class="icon-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="projects-box item">
                  <div class="projects-thumb">
                    <a href="#">
                      <img src="/assets/img/news/img2.jpg" alt="" />
                    </a>
                  </div>
                  <div class="projects-content">
                    <h4>
                      <a href="#">high end flooring trends</a>
                    </h4>
                    <div class="recent-meta">
                      <span class="date">
                        <i class="fa fa-file-text-o"></i> SEPT 12, 2015
                      </span>
                      <span class="comments">
                        <i class="fa fa-comments-o"></i> No Comments
                      </span>
                    </div>
                    <p class="projects-desc">
                      Constructor explains how you can enjoy high end flooring
                      trends like textured wood and realistic stones with new
                      laminate flooring.
                    </p>
                    <a href="#" class="btn btn-effect">
                      Read More <i class="icon-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="projects-box item">
                  <div class="projects-thumb">
                    <a href="#">
                      <img src="/assets/img/news/img3.jpg" alt="" />
                    </a>
                  </div>
                  <div class="projects-content">
                    <h4>
                      <a href="#">stones with new laminate flooring</a>
                    </h4>
                    <div class="recent-meta">
                      <span class="date">
                        <i class="fa fa-file-text-o"></i> SEPT 12, 2015
                      </span>
                      <span class="comments">
                        <i class="fa fa-comments-o"></i> No Comments
                      </span>
                    </div>
                    <p class="projects-desc">
                      Constructor explains how you can enjoy high end flooring
                      trends like textured wood and realistic stones with new
                      laminate flooring.
                    </p>
                    <a href="#" class="btn btn-effect">
                      Read More <i class="icon-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="projects-box item">
                  <div class="projects-thumb">
                    <a href="#">
                      <img src="/assets/img/news/img4.jpg" alt="" />
                    </a>
                  </div>
                  <div class="projects-content">
                    <h4>
                      <a href="#">majority have suffered alteration</a>
                    </h4>
                    <div class="recent-meta">
                      <span class="date">
                        <i class="fa fa-file-text-o"></i> SEPT 12, 2015
                      </span>
                      <span class="comments">
                        <i class="fa fa-comments-o"></i> No Comments
                      </span>
                    </div>
                    <p class="projects-desc">
                      Constructor explains how you can enjoy high end flooring
                      trends like textured wood and realistic stones with new
                      laminate flooring.
                    </p>
                    <a href="#" class="btn btn-effect">
                      Read More <i class="icon-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="projects-box item">
                  <div class="projects-thumb">
                    <a href="#">
                      <img src="/assets/img/news/img5.jpg" alt="" />
                    </a>
                  </div>
                  <div class="projects-content">
                    <h4>
                      <a href="#">Finding New Buildings</a>
                    </h4>
                    <div class="recent-meta">
                      <span class="date">
                        <i class="fa fa-file-text-o"></i> SEPT 12, 2015
                      </span>
                      <span class="comments">
                        <i class="fa fa-comments-o"></i> No Comments
                      </span>
                    </div>
                    <p class="projects-desc">
                      Constructor explains how you can enjoy high end flooring
                      trends like textured wood and realistic stones with new
                      laminate flooring.
                    </p>
                    <a href="#" class="btn btn-effect">
                      Read More <i class="icon-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
