import React from "react";

export default function StudentPortal() {
  return (
    <div>
      <div
        class="page-header"
        style={{
          background: "url(/assets/img/bg/banner.jpg)",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="entry-title">Student Portal</h2>
              <div class="breadcrumb">
                <a href="index.html">Home</a>
                <span class="crumbs-spacer">
                  <i class="fa fa-angle-double-right"></i>
                </span>
                <span class="current">Student Portal</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="content" class="error-bg">
        <div class="container">
          <div class="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div
                class="loginSection"
                style={{
                  backgroundColor: "white",
                  padding: "20px"
                }}
              >
                <h2 class="big-title text-center">
                  Please login to Student Portal
                </h2>
                <p class="loginError" id="signInError">
                  {" "}
                </p>

                <form action="" method="post">
                  <div class="loginSectionInner">
                    <div class="form-group">
                      <div class="controls">
                        <input
                          autocomplete="off"
                          class="form-control"
                          id="StudentPortalAuthenticate_AUEmail"
                          name="StudentPortalAuthenticate.AUEmail"
                          placeholder="Please enter your Reads Collegiate email"
                          required="required"
                          type="text"
                          value=""
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="controls">
                        <input
                          autocomplete="off"
                          class="form-control"
                          id="StudentPortalAuthenticate_AUPassword"
                          name="StudentPortalAuthenticate.AUPassword"
                          placeholder="Password"
                          required="required"
                          type="password"
                          value=""
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <button type="submit" id="submit" class="btn btn-effect btn-block">
                      <i class="fa fa-check"></i>Sign In
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
