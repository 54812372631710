import React from "react";

export default function ProudFaculty() {
  return (
    <div>
      <div
        className="page-header"
        style={{ background: "url(/assets/img/bg/banner.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="entry-title">
                <span>Proud </span>Faculty
              </h2>
              <div className="breadcrumb">
                <a href="index.html">Home</a>
                <span className="crumbs-spacer">
                  <i className="fa fa-angle-double-right"></i>
                </span>
                <span className="current">Proud Faculty</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Portfolio Section */}
      <section id="portfolio" className="section">


        <div id="portfolio-list">
          <div className="row">
            <div className="col-md-12">
              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/Hamid_Khan_English.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Muhammad Hamid_Khan</h4>
                        <p>CEO & English Lecturer</p>
                      </div>
                      <div class="social-media">
                      <a
                href="https://www.facebook.com/readscollegiate.edu.pk"
                target="_blank"
                rel="noopener noreferrer"
                className="facebook"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/ReadsCollegiate"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/readscollegiate"
                target="_blank"
                rel="noopener noreferrer"
                className="instagram"
              >
                </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Muhammad Hamid_Khan</h4>
                    <p>CEO & English Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Syed_Anwar_Ul_Haq_Physics.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Syed Anwar Ul Haq</h4>
                        <p>Director & Physics Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Syed Anwar Ul Haq</h4>
                    <p>Director & Physics Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/Umair_Khan_Biology.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Muhammad Umair Khan</h4>
                        <p>Director & Biology Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Muhammad Umair Khan</h4>
                    <p>Director & Biology Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/team-04.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Qazi Ekram Muneer</h4>
                        <p>Director & Physics Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Qazi Ekram Muneer</h4>
                    <p>Director & Physics Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Muhammad_Asim_Physics.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Muhammad Asim</h4>
                        <p>Director & Physics Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Muhammad Asim</h4>
                    <p>Director & Physics Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/Khurram_English.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Khurram</h4>
                        <p>English Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Khurram</h4>
                    <p>English Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Abdur_Rehman_Accounting.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Abdur Rehman</h4>
                        <p>Accounting Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Abdur Rehman</h4>
                    <p>Accounting Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/Badar_Alam_Physics.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Badar Alam</h4>
                        <p>Physics Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Badar Alam</h4>
                    <p>Physics Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img src="/assets/img/team/Dr_Nasir_Chemistry.png" alt="" />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Dr.Nasir </h4>
                        <p>Chemistry Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Badar Alam</h4>
                    <p>Physics Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Hassan_Ud_Din_Computer.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Hassan Ud Din</h4>
                        <p>Computer Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Hassan Ud Din</h4>
                    <p>Computer Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Irfan_Qureshi_English.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Irfan Qureshi</h4>
                        <p>English Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Irfan Qureshi</h4>
                    <p>English Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Muhammad_Usman_Chemistry.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Muhammad Usman</h4>
                        <p>Chemistry Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Muhammad Usman</h4>
                    <p>Chemistry Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Muhammad_Yasir_Chemistry.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Muhammad Yasir</h4>
                        <p>Chemistry Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Muhammad Yasir</h4>
                    <p>Chemistry Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Rafay_Mamji_Biology.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Rafay Mamji</h4>
                        <p>Biology Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Rafay Mamji</h4>
                    <p>Biology Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Saqib_Iqbal_Statistics_&_Business_Maths.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Saqib Iqbal</h4>
                        <p>Statistics & Business Maths Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Saqib Iqbal</h4>
                    <p>Statistics & Business Maths Lecturer</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 p-b-15">
                <div class="team-member">
                  <div class="team-thumbnail">
                    <img
                      src="/assets/img/team/Umair_Mumel_Economics_&_Banking.png"
                      alt=""
                    />
                    <div class="overlay">
                      <div class="info name">
                        <h4>Umair Mumel</h4>
                        <p>Economics & Banking Lecturer</p>
                      </div>
                      <div class="social-media">
                        <a
                          href="https://www.facebook.com/readscollegiate.edu.pk"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://twitter.com/ReadsCollegiate"
                          target="_blank"
                          class="twitter"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/readscollegiate"
                          target="_blank"
                          class="instagram"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <h4>Umair Mumel</h4>
                    <p>Economics & Banking Lecturer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
