import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <footer>
        <div class="container">
          <div class="row footer-widgets">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="footer-widget contact-widget">
                <h4 class="p-0">
                  <img
                    src="assets/img/footer-logo.png"
                    class="img-responsive"
                    alt="Footer Logo"
                  />
                </h4>
                <p>Let's Read at READS!</p>
                <p>
                  We provide quality education with inter-personal skills. We
                  teach our students not to produce good result only but also be
                  successful in their lives.
                </p>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 col-xs-12">
              <div class="footer-widget">
                <h4>INFORMATION</h4>
                <ul class="address">
                  <li>
                    <Link to="/about">
                      <i class="fa fa-dot-circle-o"></i> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/proudfaculty">
                      <i class="fa fa-dot-circle-o"></i> Proud Faculty
                    </Link>
                  </li>
                  <li>
                    <Link to="gallery">
                      <i class="fa fa-dot-circle-o"></i> Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="contact-us">
                      <i class="fa fa-dot-circle-o"></i> Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="contact-us">
                      <i class="fa fa-dot-circle-o"></i> FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to="contact-us">
                      <i class="fa fa-dot-circle-o"></i> Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="footer-widget">
                <h4>CONNECT</h4>
                <ul class="address">
                  <li>
                    <i class="fa fa-map-marker"></i> NAZIMABAD CAMPUS: 1-A, 1/2,
                    Falak Avenue, Nazimabad # 1, Karachi
                  </li>
                  <li style={{ borderBottom: "1px solid #FFCC1D" }}>
                    <i class="fa fa-phone"></i> +(021) 36620040-41
                  </li>
                  <li>
                    <i class="fa fa-map-marker"></i> SAKHI HASSAN CAMPUS: C-4
                    SECTOR 14-A, SHADMAN TOWN, NORTH NAZIMABAD, KARACHI
                  </li>
                  <li style={{ borderBottom: "1px solid #FFCC1D" }}>
                    <i class="fa fa-phone"></i> +(021) 36952901, 36904286
                  </li>
                  <li>
                    <Link to="mailto:info@readscollegiate.com">
                      <i class="fa fa-envelope"></i> info@readscollegiate.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="copyright-section" style={{ backgroundColor: "#0C1951" }}>
        <div class="container" style={{ marginBottom: "80px" }}>
          <div class="row">
            <div class="col-sm-12 mb-5">
              <p class="m-b-0 text-white" style={{ color: "#fff" }}>
                Copyright &copy; Reads Collegiate 2024.Powered by Think Simple
                Solution +92-331-3766599 | +92-331-2766287.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
